import { styled, css } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { CardSizes } from '../../linkCardsTypes';
import { cardsDimensions } from '../CardOrientationWrapper';

const commonSmartlinkOverrideStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-link-container]': {
		boxSizing: 'border-box',

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		"& div[data-smart-block]:not(:has(div[data-smart-element-media='image']))": {
			flexShrink: 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		"& div[data-smart-block]:first-of-type:not(:has(span[data-vc='emoji-component'])):has(img)": {
			height: '100%',
			flexShrink: 1,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'& div[data-smart-element-media]': {
				height: '100%',
			},
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CommonCardWrapperStyles = styled.div<{
	isInViewMode: boolean;
	isEmptyCard?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
}>(commonSmartlinkOverrideStyles, {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-link-container]': {
		overflow: 'hidden',
		boxShadow: token('elevation.shadow.raised'),
		border: 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		...({ isInViewMode, isEmptyCard }) =>
			isInViewMode &&
			!isEmptyCard && {
				'&:hover': {
					boxShadow: `0px 5px 12px 0px ${token('color.border')}`,
					cursor: 'pointer',
				},
			},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CommonCarouselWrapperStyles = styled.div<{
	isCardSelected?: boolean;
	isHeroCard?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
}>(commonSmartlinkOverrideStyles, {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-link-container]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		border: ({ isCardSelected }) =>
			isCardSelected
				? `${token('border.width')} ${token('color.border.selected')} solid`
				: `${token('border.width')} ${token('color.border')} solid`,

		'&:hover': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			cursor: ({ isHeroCard }) => (isHeroCard ? 'cursor' : 'pointer'),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyAndSmallCardWrapper = styled.div<{
	cardHeight: number;
	size: string;
	isPublishDateShown?: boolean;
	isEmptyCard?: boolean;
	hasImage?: boolean;
	isAvatarShown?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-link-container]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		...({ size, hasImage }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			size === CardSizes.SMALL &&
			hasImage && {
				paddingInlineStart: token('space.1000'),
				paddingInlineEnd: token('space.100'),
			},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: ({ cardHeight }) => `${cardHeight}px`,
		justifyContent: 'center',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-element-group]': {
		gap: token('space.050'),
		color: token('color.text.subtlest'),

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		"& div[data-smart-element-group]:has(span[data-smart-element='ModifiedOn'])": {
			marginInlineStart: token('space.0'),
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"> span[data-smart-element='ModifiedOn']": {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles
			...({ hasImage }) =>
				!hasImage && {
					display: 'flex',
				},
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"span[data-smart-element='OwnedByGroup'] > ul": {
			marginRight: 0,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'& > li': {
				marginInlineEnd: 0,
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'& > li::before': {
				content: '',
			},
		},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-element-media]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		width: ({ size }) => (size === CardSizes.SMALL ? '72px' : undefined),
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	"& div[data-smart-block]:has(a[data-smart-element='Title'])": {
		gap: token('space.0'),
		overflow: 'visible',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"& a[data-smart-element='Title']": {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			'-webkit-line-clamp': ({ hasImage, isAvatarShown }) =>
				!hasImage && !isAvatarShown ? '3' : '2',
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MediumLargeCardWrapper = styled.div<{
	cardHeight: number;
	isLargeCard?: boolean;
	hasImage?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-link-container]': {
		// TODO Remove this custom styling when CTE-2894 is done
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: ({ cardHeight }) => `${cardHeight}px`,
		justifyContent: 'center',
		gap: token('space.100'),

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors,
		"& a[data-smart-element='Title']": {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			'-webkit-line-clamp': ({ hasImage }) => (!hasImage ? '3' : '2'),
		},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-element-group]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		display: 'block !important',
		overflow: 'visible',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'>span[data-smart-element-date-time]': {
			display: 'flex',
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"span[data-smart-element='OwnedByGroup'] > ul": {
			marginInlineEnd: token('space.100'),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'& > li::before': {
				content: '',
			},
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ExtraSmallCardWrapper = styled.div<{
	cardHeight: number;
	minWidth: number;
	isPublishDateShown?: boolean;
	isEmptyCard?: boolean;
	hasEmoji?: boolean;
	maxWidth: number;
	isCardSelected?: boolean;
}>({
	'--preview-block-width': '72px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-link-container]': {
		overflow: 'hidden',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		...({ hasEmoji }) =>
			hasEmoji && {
				paddingLeft: `calc(var(--preview-block-width) + ${token('space.200')})`,
			},
		flexShrink: 1,
		flexDirection: 'row',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: ({ cardHeight }) => `${cardHeight}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		minWidth: ({ minWidth }) => `${minWidth}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		maxWidth: ({ maxWidth }) => `${maxWidth}px`,
		marginRight: '0px',
		marginLeft: '0px',
		justifyContent: 'flex-start',
		alignItems: 'center',
		cursor: 'pointer',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeroCardWrapper = styled.div<{
	cardHeight: number;
	isPublishDateShown?: boolean;
	isEmptyCard?: boolean;
	isVerticalLayout: boolean;
}>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${cardsDimensions[CardSizes.HERO].minWidth}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-smart-link-container]': {
		'--preview-block-width': 'calc(100% - 256px)',
		// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "8px" do not match and can't be replaced automatically.
		borderRadius: token('border.radius.100', '8px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		paddingLeft: ({ isVerticalLayout }) =>
			!isVerticalLayout
				? `calc(var(--preview-block-width) + ${token('space.400')})`
				: token('space.300'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: ({ cardHeight }) => `${cardHeight}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		...({ isEmptyCard }) =>
			isEmptyCard && {
				paddingBlock: token('space.200'),
			},
		gap: token('space.100'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		...({ isVerticalLayout }) =>
			isVerticalLayout && {
				display: 'flex',
				paddingBlock: token('space.300'),
			},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div[smart-footer-block-resolved-view]': {
		flexGrow: 1,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> div': {
			alignSelf: 'flex-end',
		},
	},
});
