import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button/standard-button';
import { Flex, xcss, Box } from '@atlaskit/primitives';
import { WidthObserver } from '@atlaskit/width-detector';

import { CardSizes } from '../../../linkCardsTypes';
import { cardsDimensions } from '../../CardOrientationWrapper';

import { CommonErrorStateWrapper, HeroErrorStateImage } from './errorStateStyles';

const errorTextContainerStyles = xcss({
	flexDirection: 'column',
	paddingLeft: 'space.400',
	paddingTop: 'space.300',
	paddingRight: 'space.300',
	paddingBottom: 'space.300',
	marginRight: 'space.025',
	alignSelf: 'flex-start',
	height: '100%',
});

const titleStyles = xcss({
	font: token('font.heading.medium'),
	marginTop: 'space.100',
});

const descriptionStyles = xcss({
	color: 'color.text.subtlest',
	font: token('font.body'),
	flexGrow: 1,
});

export type HeroErrorStateProps = {
	cardId: string;
	image: SVGElement;
	link?: string;
	heading: Record<string, React.ReactNode>;
	description?: Record<string, React.ReactNode>;
	subheading?: Record<string, React.ReactNode>;
	cardHeight: number;
	isInViewMode: boolean;
	hideLink?: boolean;
	ariaLabel?: string;
	isVerticalHeroCard?: boolean;
	handleWidthObserverSetWidth?: (width: number) => void;
};

export const HeroErrorState = ({
	cardId,
	image,
	heading,
	subheading,
	cardHeight,
	link,
	isInViewMode,
	hideLink,
	description,
	ariaLabel,
	isVerticalHeroCard,
	handleWidthObserverSetWidth,
}: HeroErrorStateProps) => {
	return (
		<Box
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss={xcss({
				position: 'relative',
			})}
		>
			<CommonErrorStateWrapper
				id={cardId}
				cardHeight={cardHeight}
				isInViewMode={isInViewMode}
				isHeroCard
				aria-label={ariaLabel}
				tabIndex={0}
				isVerticalHeroCard={isVerticalHeroCard}
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					minWidth: cardsDimensions[CardSizes.HERO].minWidth,
				}}
			>
				<HeroErrorStateImage
					src={image}
					style={{
						width: !isVerticalHeroCard ? 'calc(100% - 256px)' : '100%',
						height: !isVerticalHeroCard ? 'initial' : '45%',
					}}
				/>
				<Flex xcss={errorTextContainerStyles} gap="space.100">
					<Box xcss={titleStyles}>
						<FormattedMessage {...heading} />
					</Box>
					<Box xcss={descriptionStyles}>
						<FormattedMessage {...description} />
					</Box>
					{!!subheading && !hideLink && (
						<Button href={link} target="_blank">
							<FormattedMessage {...subheading} />
						</Button>
					)}
				</Flex>
			</CommonErrorStateWrapper>
			{handleWidthObserverSetWidth && (
				<WidthObserver setWidth={handleWidthObserverSetWidth} offscreen />
			)}
		</Box>
	);
};
