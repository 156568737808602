import React, { useState, useEffect } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { MediaClientProvider } from '@atlaskit/media-client-react';
import type { MediaClientConfig } from '@atlaskit/media-core';
import { MediaImage } from '@atlaskit/media-image';
import WarningIcon from '@atlaskit/icon/core/warning';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import type { ExtensionParams } from '@atlaskit/editor-common/extensions';

import { createRendererMediaProvider } from '@confluence/fabric-media-support';
import {
	MacroExperienceStop,
	MacroExperienceSuccess,
	getExperienceName,
	getMacroAttributesFromADFNode,
} from '@confluence/macro-tracker';
import type { Mode } from '@confluence/macro-tracker';

export const i18n = defineMessages({
	imageFailedMessage: {
		id: 'custom-sites-extensions.link-cards.image-failed-to-load',
		defaultMessage: 'Image failed to load',
		description: "Message shown when the extensions's uploaded image failed to load",
	},
	warningIcon: {
		id: 'custom-sites-extensions.link-cards.warning',
		defaultMessage: 'Warning icon for image failed to load',
		description:
			"Label text for a warning icon that's shown when the extensions's uploaded image failed to load",
	},
});

const errorWrapperStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	height: '100%',
	justifyContent: 'center',
	alignItems: 'center',
	background: token('color.background.accent.gray.subtlest'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImageWrapper = styled.img({
	height: '100%',
	width: '100%',
	aspectRatio: 'unset',
	objectFit: 'cover',
});

const uploadingImageStyles = xcss({
	width: '100%',
	height: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	background: token('color.background.accent.gray.subtlest'),
});

type CustomImageUploadProps = {
	/**
	 * imageSrc can be an Unsplash URL or a "collectionId/imageId" string.
	 */
	imageSrc?: string;
	contentId: string;
	imageAltText?: string;
	shouldShowErrorMessage?: boolean;
	extensionNode?: ExtensionParams<any>;
	mode?: Mode;
};

const getImageMediaLocation = (
	activeContentId: string,
	maybeCustomCoverImage: string,
): [string, string] => {
	if (maybeCustomCoverImage.indexOf('/') !== -1) {
		const temp = maybeCustomCoverImage.split('/');
		return [temp[0], temp[1]];
	}
	return [activeContentId, maybeCustomCoverImage];
};

export const CustomImageUploadRenderer = ({
	contentId,
	imageSrc = '',
	imageAltText,
	shouldShowErrorMessage = false,
	extensionNode,
	mode,
}: CustomImageUploadProps) => {
	const intl = useIntl();
	const [collectionId, imageId] = getImageMediaLocation(contentId, imageSrc);
	const mediaConfig = useMediaConfig({ collectionId });
	const attributes = extensionNode ? getMacroAttributesFromADFNode(extensionNode) : {};
	const extensionKey = extensionNode?.extensionKey;

	if (!mediaConfig || !mediaConfig.authProvider) {
		return null;
	}

	const errorState = (
		<Box xcss={errorWrapperStyles} testId="custom-image-upload-error">
			<WarningIcon
				label={intl.formatMessage(i18n.warningIcon)}
				color={token('color.icon.subtle')}
			/>
			{shouldShowErrorMessage && <FormattedMessage {...i18n.imageFailedMessage} />}
		</Box>
	);

	return (
		<MediaClientProvider clientConfig={mediaConfig}>
			<MediaImage
				identifier={{
					mediaItemType: 'file',
					id: imageId,
					collectionName: mediaConfig?.collection,
				}}
				// Force rerender image on ID change until https://product-fabric.atlassian.net/browse/CXP-2899 is fixed
				key={imageId}
				mediaClientConfig={{
					authProvider: mediaConfig.authProvider,
				}}
				apiConfig={{
					allowAnimated: true,
					mode: 'fit',
					upscale: false,
				}}
			>
				{({ data, loading, error }) => {
					if (loading) {
						return <Box testId="custom-image-upload-loading" xcss={uploadingImageStyles} />;
					}

					if (error || !data?.src) {
						return (
							<>
								{errorState}
								{extensionNode && mode ? (
									<MacroExperienceStop
										error={new Error('Image failed to load in CustomImageUploadRenderer')}
										mode={mode}
										name={getExperienceName(mode, extensionNode)}
										attributes={attributes}
										contentId={contentId}
										source={`${extensionKey}CustomImageUploadRenderer`}
									/>
								) : null}
							</>
						);
					}

					return (
						<>
							<ImageWrapper src={data.src} alt={imageAltText} data-testid="custom-image-upload" />
							{!!mode && !!extensionNode && (
								<MacroExperienceSuccess
									name={getExperienceName(mode, extensionNode)}
									contentId={contentId}
									extensionKey={extensionNode.extensionKey}
									mode={mode}
									attributes={attributes}
								/>
							)}
						</>
					);
				}}
			</MediaImage>
		</MediaClientProvider>
	);
};

interface ExtendedMediaClientConfig extends MediaClientConfig {
	collection: string;
}

function useMediaConfig({
	collectionId,
}: {
	collectionId: string;
}): ExtendedMediaClientConfig | null {
	const [mediaConfig, setMediaConfig] = useState<ExtendedMediaClientConfig | null>(null);

	useEffect(() => {
		async function createMediaConfig() {
			const { viewMediaClientConfig } = await createRendererMediaProvider({
				contentId: collectionId,
				isExternalShare: false,
			});

			if (!viewMediaClientConfig) {
				setMediaConfig(null);
				return;
			}

			setMediaConfig({
				collection: `contentId-${collectionId}`,
				authProvider: viewMediaClientConfig.authProvider,
			});
		}

		void createMediaConfig();
	}, [collectionId]);

	return mediaConfig;
}
