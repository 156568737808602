import React, { useEffect, useRef } from 'react';
import { styled } from '@compiled/react';

import { WidthObserver } from '@atlaskit/width-detector';
import { token } from '@atlaskit/tokens';

import { CardSizes } from '../linkCardsTypes';
import { useCustomSitesExtensions } from '../../shared-components/useCustomSitesExtensions';

type CardOrientationWrapperProps = {
	isFirstRowFull: boolean;
	cardWidthOnFullRow: number;
	cardSize: CardSizes;
	setWidth?: React.Dispatch<React.SetStateAction<number>>;
	testId?: string;
	scrollToCard: boolean;
	children: React.ReactElement;
	index: number;
	requestScrollIndex: (index: number) => void;
	clearScrollToCard: () => void;
};

type CardWrapperProps = {
	isFirstRowFull: boolean;
	cardWidthOnFullRow: number;
	cardMinWidth: number;
	cardMaxWidth: number;
};

type CardDimensions = {
	minWidth: number;
	maxWidth: number;
	height: number;
};

type CardsDimensions = {
	[key in CardSizes]: CardDimensions;
};

export const cardsDimensions: CardsDimensions = {
	[CardSizes.EXTRA_SMALL]: {
		minWidth: 244,
		maxWidth: 250,
		height: 72,
	},
	[CardSizes.SMALL]: {
		minWidth: 228,
		maxWidth: 380,
		height: 72,
	},
	[CardSizes.MEDIUM]: {
		minWidth: 240,
		maxWidth: 308,
		height: 312,
	},
	[CardSizes.LARGE]: {
		minWidth: 358,
		maxWidth: 640,
		height: 312,
	},
	[CardSizes.HERO]: {
		minWidth: 334,
		maxWidth: 640,
		height: 350,
	},
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardWrapper = styled.div<CardWrapperProps>({
	flex: 1,
	paddingBlock: token('space.025'),
	alignSelf: 'center',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...({ isFirstRowFull, cardWidthOnFullRow, cardMinWidth, cardMaxWidth }) => ({
		minWidth: isFirstRowFull ? `${cardWidthOnFullRow}px` : `${cardMinWidth}px`,
		maxWidth: isFirstRowFull ? `${cardWidthOnFullRow}px` : `${cardMaxWidth}px`,
	}),
});

export const CardOrientationWrapper = ({
	isFirstRowFull,
	cardWidthOnFullRow,
	cardSize,
	setWidth,
	children,
	testId = 'card-wrapper',
	scrollToCard,
	clearScrollToCard,
	index,
	requestScrollIndex,
}: CardOrientationWrapperProps) => {
	const cardRef = useRef<HTMLDivElement>(null);
	const [{ selectedExtensionLocalId, selectedToEditCardId }] = useCustomSitesExtensions();
	const { cardId, extensionLocalId } = children.props;

	useEffect(() => {
		if (cardRef.current) {
			// Scroll to this card as long as its config panel is open OR no config panel is open.
			// If no config panel is open, that covers the case in edit mode where user has clicked
			// scroll buttons on a row-oriented cards instance which they aren't actively editing.
			if (
				scrollToCard &&
				(!selectedExtensionLocalId || extensionLocalId === selectedExtensionLocalId)
			) {
				cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
				// Clear scrollIndex to prevent re-invoking scroll effect later unexpectedly
				clearScrollToCard();
			}
			// Handle the case where user has selected this card to edit from the config panel.
			if (
				selectedToEditCardId &&
				selectedToEditCardId === cardId &&
				extensionLocalId === selectedExtensionLocalId
			) {
				// Now that we've reached the right card that user is selecting to edit,
				// trigger the scrolling hook to recalculate scroll window and index so
				// that we can rerender invoking the scroll effect
				requestScrollIndex(index);
			} else if (!selectedToEditCardId) {
				// if no card is selected to edit, set index to -1 indicate that it is not currently being used
				requestScrollIndex(-1);
			}
		}
	}, [
		cardRef,
		cardId,
		extensionLocalId,
		index,
		scrollToCard,
		clearScrollToCard,
		selectedExtensionLocalId,
		selectedToEditCardId,
		requestScrollIndex,
	]);

	return (
		<CardWrapper
			ref={cardRef}
			data-testid={testId}
			isFirstRowFull={isFirstRowFull}
			cardWidthOnFullRow={cardWidthOnFullRow}
			cardMinWidth={cardsDimensions[cardSize].minWidth}
			cardMaxWidth={cardsDimensions[cardSize].maxWidth}
		>
			{children}
			{setWidth && <WidthObserver setWidth={setWidth} offscreen />}
		</CardWrapper>
	);
};
