import React from 'react';
import type { FC } from 'react';
import { styled } from '@compiled/react';

import { LazyEmojiComponentLoader } from '@confluence/emoji-title';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles
const EmojiWrapper = styled.span<{ width: number }>(({ width }) => ({
	display: 'flex',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
	maxWidth: `${width}px`,
}));

export const EmojiRenderer: FC<{
	emojiId: string | null;
	context?: string;
	height?: number;
	width?: number;
}> = ({ emojiId, context = 'linkCard', height = 40, width = 40 }) =>
	!!emojiId ? (
		<LazyEmojiComponentLoader
			emoji={emojiId}
			height={height}
			wrapper={(props) => <EmojiWrapper {...props} width={width} />}
			context={context}
			renderResourcedEmoji
		/>
	) : null;
