import { styled } from '@compiled/react';

import Image from '@atlaskit/image';
import { token } from '@atlaskit/tokens';

import { CardSizes } from '../../../linkCardsTypes';
import { cardsDimensions } from '../../CardOrientationWrapper';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CommonErrorStateWrapper = styled.div<{
	cardHeight: number;
	isInViewMode: boolean;
	isHeroCard?: boolean;
	isExtraSmallCard?: boolean;
	isCardSelected?: boolean;
	isVerticalHeroCard?: boolean;
}>({
	display: 'flex',
	borderRadius: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	boxShadow: ({ isExtraSmallCard, isHeroCard }) =>
		isExtraSmallCard || isHeroCard ? 'none' : token('elevation.shadow.raised'),
	backgroundColor: token('color.background.input'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...({ isExtraSmallCard }) =>
		isExtraSmallCard && {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			minWidth: `${cardsDimensions[CardSizes.EXTRA_SMALL].minWidth}px`,
		},
	overflow: 'hidden',
	//subtracting [borderTop/Bottom] for the border height being added in herocard
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ cardHeight, isHeroCard, isExtraSmallCard }) =>
		isHeroCard || isExtraSmallCard ? `${cardHeight - 2}px` : `${cardHeight}px`,
	marginTop: token('space.0'),
	marginLeft: token('space.0'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: ({ isCardSelected, isExtraSmallCard, isHeroCard }) =>
		isCardSelected
			? `${token('border.width')} ${token('color.border.selected')} solid`
			: isExtraSmallCard || isHeroCard
				? ` ${token('border.width')} ${token('color.border')} solid`
				: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...({ isInViewMode, isHeroCard, isExtraSmallCard }) =>
		isInViewMode && {
			'&:hover': {
				boxShadow:
					isHeroCard || isExtraSmallCard ? 'none' : `0px 5px 12px 0px ${token('color.border')}`,
				cursor: isHeroCard ? 'cursor' : 'pointer',
			},
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flexDirection: ({ isHeroCard, isVerticalHeroCard }) =>
		isHeroCard && isVerticalHeroCard ? 'column' : 'initial',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ErrorStateContainer = styled(CommonErrorStateWrapper)({
	alignItems: 'center',
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SmallErrorStateImage: any = styled(Image)({
	borderTopLeftRadius: '7px',
	borderBottomLeftRadius: '7px',
	backgroundColor: token('color.background.accent.gray.subtlest'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SmallErrorStateTextWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	padding: token('space.200'),
	alignSelf: 'center',

	font: token('font.body'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SmallErrorStateHeading = styled.span({
	fontWeight: token('font.weight.medium'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeroErrorStateImage: any = styled(Image)({
	borderTopLeftRadius: '7px',
	borderBottomLeftRadius: '7px',
	flexShrink: 0,
	backgroundColor: token('color.background.accent.gray.subtlest'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ErrorStateSubheading = styled.div<{ cardSize?: CardSizes }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...({ cardSize }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		cardSize !== CardSizes.SMALL && {
			padding: `${token('space.0')} ${token('space.300')}`,
			marginBottom: token('space.200'),
		},
	color: token('color.text.subtlest'),

	font: token('font.body'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ErrorStateSmallLink = styled.a({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	color: `${token('color.text.subtle')} !important`,
});
