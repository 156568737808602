import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { css } from '@compiled/react';

import {
	ElementName,
	MediaPlacement,
	PreviewBlock,
	SmartLinkSize,
	SmartLinkTheme,
	TitleBlock,
	CustomBlock,
} from '@atlaskit/smart-card';
import { CardSSR as SmartCardSSR } from '@atlaskit/smart-card/ssr';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';

import { fg } from '@confluence/feature-gating';

import { EmojiRenderer } from '../../../shared-components';
import { CustomImageUploadRenderer } from '../../../shared-components/CustomImageUploadRenderer/CustomImageUploadRenderer';
import { getImageTypeToShow } from '../../../shared-components/CustomImageUploadRenderer/getImageTypeToShow';
import type { Card as CardParams } from '../../linkCardsTypes';
import { CardSizes } from '../../linkCardsTypes';
import type { OnCardFailed, OnCardSucceeded } from '../useCardsCombinedExperienceState';
import { useTrackedSmartCardState } from '../useTrackedSmartCardState';
import { useSmartCardEmoji } from '../useSmartCardEmoji';
import { cardsDimensions } from '../CardOrientationWrapper';

import { CardErrorState } from './ErrorStates';
import { CommonCarouselWrapperStyles, ExtraSmallCardWrapper } from './cardComponentsStyles';
import { useSmartCardFallbackUrl } from './useSmartCardFallbackUrl';

const i18n = defineMessages({
	emptyStatePlaceholderTitle: {
		id: 'custom-sites-extensions.link-cards.smart-card.extra-small-card.placeholder-title',
		defaultMessage: 'Add a title',
		description: 'Placeholder title that is displayed in Smart Card when no title is provided',
	},
});

type ExtraSmallCardProps = CardParams & {
	contentId: string;
	cardHeight: number;
	isAvatarShown?: boolean;
	isPublishDateShown?: boolean;
	isInViewMode: boolean;
	onCardSucceeded: OnCardSucceeded;
	onCardFailed: OnCardFailed;
	isEmptyCard?: boolean;
	showEmoji?: boolean;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
	isCardSelected?: boolean;
	analyticsSource: string;
	extensionType: string;
};

export const ExtraSmallCard: FC<ExtraSmallCardProps> = ({
	contentId,
	cardId,
	link,
	title,
	imageSrc,
	isAvatarShown,
	isPublishDateShown,
	cardHeight,
	isInViewMode,
	onCardSucceeded,
	onCardFailed,
	isEmptyCard,
	showEmoji,
	createAnalyticsEvent,
	isCardSelected,
	analyticsSource,
	extensionType,
}) => {
	const {
		errorCardStatus,
		isNoResult,
		isRestricted,
		isUnauthorized,
		trackAndCategorizeError,
		trackSuccess,
	} = useTrackedSmartCardState(cardId, link, onCardSucceeded, onCardFailed);
	const intl = useIntl();
	const { emojiId } = useSmartCardEmoji(link);

	const fallBackUrl = useSmartCardFallbackUrl();

	const { showUnsplashImage, showExternalSiteImage, showCustomUploadedImage } = getImageTypeToShow({
		imageSrc,
	});

	const showCustomFieldsUnauthed =
		isUnauthorized && fg('company_hub_unauth_links_custom_title_fix');
	const showCustomTitleUnauthed = showCustomFieldsUnauthed && !!title;

	if (isRestricted || isNoResult)
		return (
			<CardErrorState
				isRestricted={isRestricted}
				isNoResult={isNoResult}
				link={link}
				cardId={cardId}
				cardSize={CardSizes.EXTRA_SMALL}
				cardHeight={cardHeight}
				isInViewMode={isInViewMode}
				errorStatus={errorCardStatus}
				createAnalyticsEvent={createAnalyticsEvent}
				isCardSelected={isCardSelected}
				isExtraSmallCard
				analyticsSource={analyticsSource}
				extensionType={extensionType}
			/>
		);

	return (
		<CommonCarouselWrapperStyles isCardSelected={isCardSelected}>
			<ExtraSmallCardWrapper
				minWidth={cardsDimensions[CardSizes.EXTRA_SMALL].minWidth}
				maxWidth={cardsDimensions[CardSizes.EXTRA_SMALL].maxWidth}
				cardHeight={cardHeight}
				isPublishDateShown={isPublishDateShown}
				hasEmoji
				isEmptyCard={isEmptyCard}
			>
				<SmartCardSSR
					id={cardId}
					appearance="inline"
					frameStyle="show"
					platform="web"
					ui={{
						theme: SmartLinkTheme.Black,
						clickableContainer: false,
						size: SmartLinkSize.Large,
					}}
					url={link || fallBackUrl}
					onResolve={trackSuccess}
					onError={trackAndCategorizeError}
				>
					{(showUnsplashImage || showExternalSiteImage) && (
						<PreviewBlock
							ignoreContainerPadding
							placement={MediaPlacement.Left}
							overrideUrl={imageSrc}
						/>
					)}
					{showCustomUploadedImage ? (
						<CustomBlock css={customImageUploadStyles}>
							<CustomImageUploadRenderer contentId={contentId} imageSrc={imageSrc} />
						</CustomBlock>
					) : null}
					{showEmoji && (
						<CustomBlock css={emojiStyles} testId="smart-block-link-card-emoji-container">
							<EmojiRenderer emojiId={emojiId} height={24} />
						</CustomBlock>
					)}
					<TitleBlock
						hideIcon
						text={
							// a link with no title should expect undefined
							isEmptyCard ? title || intl.formatMessage(i18n.emptyStatePlaceholderTitle) : title
						}
						maxLines={2}
						anchorTarget="_self"
						css={[
							titleBlockStyles,
							showCustomTitleUnauthed && titleBlockHideUnauthorizedStyles,
							!showCustomTitleUnauthed && isUnauthorized && titleBlockUnauthorizedStyles,
							isEmptyCard && !title
								? titleBlockLinkTextSubtlestColorStyles
								: titleBlockLinkTextColorStyles,
						]}
						metadata={!!isAvatarShown ? [{ name: ElementName.OwnedByGroup }] : []}
						subtitle={!!isPublishDateShown ? [{ name: ElementName.ModifiedOn }] : []}
					/>
				</SmartCardSSR>
			</ExtraSmallCardWrapper>
		</CommonCarouselWrapperStyles>
	);
};

const titleBlockLinkTextColorStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-element-link]': {
		color: token('color.text'),
	},
});

const titleBlockLinkTextSubtlestColorStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-element-link]': {
		color: token('color.text.subtlest'),
	},
});

const titleBlockUnauthorizedStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-element-link]': {
		display: 'none',
	},
});

const titleBlockHideUnauthorizedStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div[data-smart-element-group] > div': {
		// Need to override display block !important styles applied in cardComponentsStyles
		// TODO, let's get rid of those in rearchitecture.
		display: 'none',
	},
});

const titleBlockStyles = css({
	marginTop: token('space.0'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-element-link]': {
		fontWeight: token('font.weight.medium'),
		flexShrink: 1,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a, a:visited, a:focus, a:hover, a:active': {
		textDecoration: 'none',
		pointerEvents: 'none',
	},
	width: '100%',
});

const emojiStyles = css({
	marginRight: token('space.100'),
	flexShrink: 0,
	// emojis are being cut off
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		marginTop: token('space.025'),
		marginBottom: token('space.025'),
	},
});

const customImageUploadStyles = css({
	position: 'absolute',
	width: '72px',
	top: token('space.0'),
	bottom: token('space.0'),
	left: token('space.0'),
});
